import { useCallback } from 'react'
import LayoutGrid from '~/components/Layout/LayoutGrid'
import DashboardView from '~/components/Resumes/Dashboard'
import configuration from '~/configuration'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import QueryCreateProfileMutation from '~/lib/features/resumes/graphql/create-profile-mutation'
import QueryDeleteProfileMutation from '~/lib/features/resumes/graphql/delete-profile-mutation'
import QueryDuplicateProfileMutation from '~/lib/features/resumes/graphql/duplicate-profile-mutation'
import UpdateProfileMutation from '~/lib/features/resumes/graphql/mutation-update-profile'
import { IProfile } from '~/lib/features/resumes/types'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'

const DashboardContainer = () => {
  const { setToast, user, setShowLockApp } = useBoundStore()

  const {
    trigger: triggerDuplicateProfile,
    isLoading: isLoadingDuplicateProfile
  } = useSubmitCommon(QueryDuplicateProfileMutation)
  const { trigger: triggerDeleteProfile, isLoading: isLoadingDeleteProfile } =
    useSubmitCommon(QueryDeleteProfileMutation)
  const { trigger: triggerCreateProfile, isLoading: isLoadingCreateProfile } =
    useSubmitCommon(QueryCreateProfileMutation)
  const { trigger: triggerUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useSubmitCommon(UpdateProfileMutation)

  const onDuplicateProfile = useCallback(
    async (profileId: string) => {
      if (isLoadingDuplicateProfile) {
        return
      }

      setShowLockApp()
      return await triggerDuplicateProfile({
        id: profileId
      }).then((result) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            page: configuration.path.resumes.dashboard,
            setToast
          })
        }

        return result.data
      })
    },
    [isLoadingDuplicateProfile]
  )

  const onDeleteProfile = useCallback(
    async (profileId: string) => {
      if (isLoadingDeleteProfile) {
        return
      }

      setShowLockApp()
      return await triggerDeleteProfile({
        id: profileId
      }).then((result) => {
        if (result.error) {
          catchErrorFromGraphQL({
            error: result.error,
            page: configuration.path.resumes.dashboard,
            setToast
          })

          return
        }
        const { profileDelete } = result.data
        if (profileDelete.success) {
          setToast({
            open: true,
            type: 'success',
            title: 'Resume deleted'
          })
        }

        return
      })
    },
    [isLoadingDeleteProfile]
  )

  const onCreateProfile = useCallback(async () => {
    if (isLoadingCreateProfile) {
      return
    }

    setShowLockApp()
    return await triggerCreateProfile({
      templateNumber: 1,
      fullName: user?.fullName,
      email: user?.email,
      title: 'Untitled Resume'
    }).then((result) => {
      if (result.error) {
        return catchErrorFromGraphQL({
          error: result.error,
          page: configuration.path.resumes.dashboard,
          setToast
        })
      }

      return result.data
    })
  }, [user, isLoadingCreateProfile])

  const onUpdateProfile = useCallback(
    async (
      profileId: string,
      updateLocalRecord: (dataUpdate: IProfile | IProfile[]) => void,
      title: string | number
    ) => {
      if (isLoadingUpdateProfile) {
        return
      }

      return await triggerUpdateProfile({ id: profileId, title: title }).then(
        (result) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              page: configuration.path.resumes.dashboard,
              setToast
            })
          }

          const { profileUpdate } = result.data
          if (profileUpdate.profile) {
            updateLocalRecord(profileUpdate.profile)
          }
          return result.data
        }
      )
    },
    []
  )

  return (
    <LayoutGrid>
      <DashboardView
        onDuplicateProfile={onDuplicateProfile}
        onDeleteProfile={onDeleteProfile}
        onCreateProfile={onCreateProfile}
        onUpdateProfile={onUpdateProfile}
      />
    </LayoutGrid>
  )
}

export default DashboardContainer
