import { gql } from 'urql'

const QueryProfileList = gql`
  query ($page: Int, $limit: Int) {
    profilesList(limit: $limit, page: $page) {
      collection {
        id
        title
        templateNumber
        profileStrength
        createdAt
        updatedAt
        shareLink
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryProfileList
