'use client'

import { InputGroupProps } from '~/core/ui/InputGroup'
import { forwardRef } from 'react'

const InputLeftElement = forwardRef<HTMLDivElement, InputGroupProps>(
  function InputLeftElement(props, ref) {
    const { className, ...rest } = props

    return (
      <div
        ref={ref}
        className={`absolute bottom-[1px] left-[1px] top-[1px] ${className}`}
        {...rest}
      />
    )
  }
)
InputLeftElement.displayName = 'InputLeftElement'

const InputRightElement = forwardRef<HTMLDivElement, InputGroupProps>(
  function InputRightElement(props, ref) {
    const { className, ...rest } = props

    return (
      <div
        ref={ref}
        className={`absolute bottom-[1px] right-[1px] top-[1px] ${className}`}
        {...rest}
      />
    )
  }
)

InputRightElement.displayName = 'InputRightElement'

export { InputLeftElement, InputRightElement }
