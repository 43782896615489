const UpdateProfileMutation = `
  mutation (
    $id: String!,
    $title: String,
  ) {
    profileUpdate(
      input: {
        id: $id,
        title: $title,
      }
    ) {
      profile {
        id
        title
        templateNumber
        profileStrength
        createdAt
        updatedAt
        shareLink
      }
    }
  }
`

export default UpdateProfileMutation
