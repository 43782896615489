'use client'

type FCC<Props = Record<string, unknown>> = React.FC<
  React.PropsWithChildren<Props>
>

const TypographyText: FCC<{
  className?: string
  tagName?: 'p' | 'div' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  onClick?: () => void
  style?: object
}> = ({ className, children, tagName = 'p', onClick, style }) => {
  const Tag = tagName
  return (
    <Tag className={className} onClick={onClick} style={style}>
      {children}
    </Tag>
  )
}

export { TypographyText }
