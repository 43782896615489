import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '~/core/ui/Button'
import { Skeleton } from '~/core/ui/Skeleton'
import { TextButton } from '~/core/ui/TextButton'
import { IProfile } from '~/lib/features/resumes/types'

const SuggestCreateCVCard: FC<{
  data?: IProfile[]
  onSubmitDuplicateProfile: (profileId: string) => void
  onSubmitCreateProfile: () => void
}> = ({ onSubmitDuplicateProfile, data, onSubmitCreateProfile }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col rounded-lg border border-gray-300 p-10">
      <div className="px-[14px] text-center">
        <div className="text-base font-medium">
          {t('dashboard:suggest_resume_card')}
        </div>
        <div className="px-[7px]">
          <Skeleton className="mb-2 mt-12 h-[18px] w-full rounded-sm bg-gray-100" />
          <div className="flex justify-center">
            <Skeleton className="mb-6 h-[11px] w-2/3 rounded-sm bg-gray-100" />
          </div>
          <Skeleton className="mb-[7px] h-[14px] w-full rounded-sm bg-gray-100" />
          <Skeleton className="mb-[13px] h-[14px] w-2/3 rounded-sm bg-gray-100" />
          <Skeleton className="mb-[7px] h-[14px] w-full rounded-sm bg-gray-100" />
          <Skeleton className="mb-[13px] h-[14px] w-2/3 rounded-sm bg-gray-100" />
          <Skeleton className="mb-[7px] h-[14px] w-full rounded-sm bg-gray-100" />
        </div>
      </div>
      <div className="mt-[100px] text-center">
        <Button
          iconMenus="Plus"
          type="tertiary"
          label={t('button:new_resume') || ''}
          size="sm"
          className="w-full rounded"
          onClick={onSubmitCreateProfile}
        />
        <TextButton
          iconMenus="Copy"
          label={t('button:duplicate_existing') || ''}
          underline={false}
          type="secondary"
          size="sm"
          className="mt-6 w-full"
          onClick={() => data && onSubmitDuplicateProfile(data[0].id)}
        />
      </div>
    </div>
  )
}

export default SuggestCreateCVCard
