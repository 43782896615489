import { useMemo } from 'react'
import { AnyVariables, TypedDocumentNode } from 'urql'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { useInfinityQuerySearch } from '~/lib/hooks/use-infinity-query-search'
import QueryProfileList from '../graphql/query-profile-list'
import { IProfile } from '../types'

export function useQueryProfileList({
  variables,
  graphQLQuery,
  keyGraphQL
}: {
  graphQLQuery?: TypedDocumentNode<any, AnyVariables>
  keyGraphQL?: string
  variables: {
    page?: number
    limit?: number
    key?: number
  }
}) {
  const { clientGraphQL } = useContextGraphQL()

  const fetchData = async (params = {}) => {
    return clientGraphQL
      .query(graphQLQuery || QueryProfileList, params)
      .toPromise()
      .then((result: IResponseContextResult<IProfile>) => {
        if (result.error) {
          catchErrorFromGraphQL({
            error: result.error
          })

          return {
            data: [],
            meta: {
              totalRowCount: 0,
              pageSize: configuration.defaultPageSize
            }
          }
        }

        if (keyGraphQL) {
          const { collection, metadata } = result.data[keyGraphQL]
          return {
            data: collection,
            meta: {
              totalRowCount: metadata.totalCount,
              pageSize: configuration.defaultPageSize
            }
          }
        } else {
          const { profilesList } = result.data
          const collection = profilesList?.collection || []
          const metadata = profilesList?.metadata || {}
          return {
            data: collection,
            meta: {
              totalRowCount: metadata.totalCount,
              pageSize: configuration.defaultPageSize
            }
          }
        }
      })
  }
  const {
    data,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetching,
    isLoading,
    updateLocalRecord,
    deleteLocalRecord
  } = useInfinityQuerySearch<IProfile & { id?: string }>({
    configuration,
    fetchData,
    queryKey: variables
  })

  return {
    trigger: refetch,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    data: useMemo(
      () =>
        data?.pages.reduce<Array<IProfile>>(
          (all, page) => [...all, ...page.data],
          []
        ),
      [data]
    ),
    totalRowCount: data?.pages[0].meta.totalRowCount,
    updateLocalRecord,
    deleteLocalRecord
  }
}
