import { useQuery } from '@tanstack/react-query'
import { PUBLIC_APP_URL } from '~/core/constants/env'

export const previewResume = async (data: {
  shareLink: string
  id: string
}) => {
  const response = await fetch(
    `/api/resume/${data.id}/${data.shareLink.replace(
      `${PUBLIC_APP_URL}/share/cv/`,
      ''
    )}/preview`
  )
  const jsonFormat = await response.json()
  return jsonFormat
}

export const useResumePreview = (params: { shareLink: string; id: string }) => {
  const { error, isLoading, data } = useQuery({
    queryKey: [`resume-preview`, { id: params.id }],
    queryFn: () => previewResume({ shareLink: params.shareLink, id: params.id })
  })

  return { url: data?.url, isLoading, error }
}
