const QueryCreateProfileMutation = `
  mutation (
    $templateNumber: Int,
    $fullName: String,
    $email: String,
    $title: String
  ) {
    profileCreate(
      input: {
        templateNumber: $templateNumber,
        fullName: $fullName,
        email: $email,
        title: $title 
      }
    ) {
      profile {
        id
      }
    }
  }
`

export default QueryCreateProfileMutation
