'use client'

import * as TooltipWrapper from '@radix-ui/react-tooltip'
import { cva } from 'class-variance-authority'
import { ReactNode, forwardRef } from 'react'
import { cn } from '~/core/ui/utils'

const tooltipContentVariants = cva(
  'relative z-50 rounded px-2 py-[3px] bg-gray-700 dark:bg-gray-200 shadow-[0_1px_2px_-1px_rgba(0,0,0,0.1)] dark:shadow-[0_1px_2px_-1px_rgba(0,0,0,0.15)] max-w-[320px]',
  {
    variants: {
      position: {
        top: 'mb-1',
        right: 'ml-1',
        bottom: 'mt-1',
        left: 'mr-1'
      },
      variant: {
        default: '',
        topStart: '-ml-[7px]',
        topCenter: '',
        topEnd: '-mr-[7px]',
        rightStart: '',
        rightCenter: '',
        rightEnd: '',
        bottomStart: '-ml-[7px]',
        bottomCenter: '',
        bottomEnd: '-mr-[7px]',
        leftStart: '',
        leftCenter: '',
        leftEnd: ''
      }
    },
    defaultVariants: {
      position: 'top',
      variant: 'topCenter'
    }
  }
)

const tooltipContentArrowVariants = cva('absolute', {
  variants: {
    position: {
      topStart: 'bottom-[1px] translate-y-full left-2',
      topCenter: 'bottom-[1px] translate-y-full left-[50%] -translate-x-1/2',
      topEnd: 'bottom-[1px] translate-y-full right-2',
      rightStart:
        '-left-[7px] top-[6px] translate-y-1/2 translate-x-0 rotate-90',
      rightCenter:
        '-left-[7px] top-1/2 -translate-y-1/2 translate-x-0 rotate-90',
      rightEnd:
        '-left-[7px] bottom-[6px] -translate-y-1/2 translate-x-0 rotate-90',
      bottomStart: 'top-[1px] rotate-180 origin-[center_0px] left-2',
      bottomCenter:
        'top-[1px] rotate-180 origin-[center_0px] left-[50%] -translate-x-1/2',
      bottomEnd: 'top-[1px] rotate-180 origin-[center_0px] right-2',
      leftStart:
        '-right-[2px] top-[5px] translate-y-1/2 translate-x-1/2 -rotate-90',
      leftCenter:
        '-right-[2px] top-1/2 -translate-y-1/2 translate-x-1/2 -rotate-90',
      leftEnd:
        '-right-[2px] bottom-[5px] -translate-y-1/2 translate-x-1/2 -rotate-90'
    }
  },
  defaultVariants: {
    position: 'topCenter'
  }
})

const getConditions = ({
  position,
  align
}: {
  position?: 'bottom' | 'top' | 'left' | 'right'
  align?: 'start' | 'center' | 'end'
}) => {
  if (position === 'top' && align === 'end') return 'topEnd'
  if (position === 'top' && align === 'start') return 'topStart'
  if (position === 'bottom' && align === 'center') return 'bottomCenter'
  if (position === 'bottom' && align === 'end') return 'bottomEnd'
  if (position === 'bottom' && align === 'start') return 'bottomStart'
  if (position === 'left' && align === 'center') return 'leftCenter'
  if (position === 'left' && align === 'end') return 'leftEnd'
  if (position === 'left' && align === 'start') return 'leftStart'
  if (position === 'right' && align === 'center') return 'rightCenter'
  if (position === 'right' && align === 'end') return 'rightEnd'
  if (position === 'right' && align === 'start') return 'rightStart'

  return 'topCenter'
}

type tooltipPositionProps = 'bottom' | 'top' | 'left' | 'right'
type tooltipAlignProps = 'start' | 'center' | 'end'

interface TooltipProps {
  children?: ReactNode
  open?: boolean
  position?: tooltipPositionProps
  align?: tooltipAlignProps
  content?: ReactNode
  title?: string
  sideOffset?: number
  mode?: 'default' | 'icon'
  classNameConfig?: {
    content: string
    arrow?: string
  }
  classNameAsChild?: string
}

const Tooltip = forwardRef<
  React.ElementRef<typeof TooltipWrapper.Content>,
  TooltipProps
>(
  (
    {
      position = 'top',
      align = 'center',
      children,
      content,
      title,
      open = false,
      sideOffset = 5,
      mode = 'default',
      classNameConfig,
      classNameAsChild
    },
    ref
  ) => {
    return (
      <TooltipWrapper.Provider delayDuration={300}>
        <TooltipWrapper.Root {...(open ? { open } : {})}>
          <TooltipWrapper.Trigger asChild>
            <div className={classNameAsChild}>{children}</div>
          </TooltipWrapper.Trigger>

          <TooltipWrapper.Portal>
            <TooltipWrapper.Content
              ref={ref}
              side={position}
              align={align}
              className={cn(
                tooltipContentVariants({
                  position,
                  variant:
                    mode === 'icon'
                      ? getConditions({ position, align })
                      : 'default',
                  className: classNameConfig?.content
                }),
                content || title ? '' : 'hidden'
              )}
              sideOffset={sideOffset}>
              {title ? (
                <p className="break-words text-xs font-medium text-gray-100 dark:text-gray-900">
                  {title}
                </p>
              ) : null}
              {content ? (
                <>
                  <p
                    className={cn(
                      'break-words text-xs dark:text-gray-900',
                      !title ? 'text-gray-100' : 'text-gray-300'
                    )}>
                    {content}
                  </p>
                  <div
                    className={cn(
                      tooltipContentArrowVariants({
                        position: getConditions({ position, align }),
                        className: classNameConfig?.arrow
                      })
                    )}>
                    <svg
                      width="10"
                      height="5"
                      viewBox="0 0 30 10"
                      preserveAspectRatio="none">
                      <polygon
                        className="fill-gray-700 dark:fill-white"
                        points="0,0 30,0 15,10"></polygon>
                    </svg>
                  </div>
                </>
              ) : null}
            </TooltipWrapper.Content>
          </TooltipWrapper.Portal>
        </TooltipWrapper.Root>
      </TooltipWrapper.Provider>
    )
  }
)

Tooltip.displayName = 'Tooltip'

export { Tooltip }
export type { TooltipProps, tooltipAlignProps, tooltipPositionProps }
