const QueryDeleteProfileMutation = `
  mutation (
    $id: String!
  ) {
    profileDelete(
      input: {
        id: $id,
      }
    ) {
      success
    }
  }
`

export default QueryDeleteProfileMutation
