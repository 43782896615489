import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'

const EmptyView = ({
  onSubmitCreateProfile
}: {
  onSubmitCreateProfile: () => void
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex min-h-[calc(100vh_-_176px)] items-center justify-center">
      <div className="max-w-[400px] text-center">
        <Image
          src="/img/empty/file_empty.svg"
          width={100}
          height={100}
          alt="file empty"
        />
        <div className="mt-8">
          <TypographyText className="mb-1 text-center text-lg font-medium text-gray-900">
            {t('dashboard:empty:title')}
          </TypographyText>
          <TypographyText className="text-center text-base text-gray-500">
            {t('dashboard:empty:description')}
          </TypographyText>
          <div className="flex justify-center">
            <TextButton
              iconMenus="Plus"
              label={t('button:new_resume')}
              size="lg"
              underline={false}
              className="mt-2"
              onClick={onSubmitCreateProfile}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyView
