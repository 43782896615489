import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import withTranslateProvider from 'src/hoc/with-translate-provider'
import DashboardContainer from '~/features/resumes'
import en_dashboard from '../../public/locales/en/dashboard.json'
import vn_dashboard from '../../public/locales/vn/dashboard.json'
import HeadMetaTags from '~/components/HeadMetaTags'
import configuration from '~/configuration'

function DashboardPage() {
  const queryClientDashboardPage = new QueryClient()

  return (
    <QueryClientProvider client={queryClientDashboardPage}>
      <HeadMetaTags title={configuration.seo.resumes.dashboard} />
      <DashboardContainer />
    </QueryClientProvider>
  )
}

export default withTranslateProvider(DashboardPage, {
  en: { dashboard: en_dashboard },
  vn: { dashboard: vn_dashboard }
})
