import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import configuration from '~/configuration'
import { Button } from '~/core/ui/Button'
import { Skeleton } from '~/core/ui/Skeleton'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { cn } from '~/core/ui/utils'
import { useQueryProfileList } from '~/lib/features/resumes/hooks/use-query-profile-list'
import { IProfile } from '~/lib/features/resumes/types'
import useBoundStore from '~/lib/store'
import EmptyView from './EmptyView'
import ResumeCard from './ResumeCard'
import SuggestCreateCVCard from './SuggestCreateCVCard'

const DashboardView: FC<{
  onDuplicateProfile?: (profileId: string) => Promise<void>
  onDeleteProfile?: (profileId: string) => Promise<void>
  onUpdateProfile?: (
    profileId: string,
    updateLocalRecord: (dataUpdate: IProfile | IProfile[]) => void,
    title: string | number
  ) => Promise<void>
  onCreateProfile?: () => Promise<{
    profileCreate: { profile: { id: string } }
  }>
}> = ({
  onDuplicateProfile,
  onDeleteProfile,
  onCreateProfile,
  onUpdateProfile
}) => {
  const { showLockApp, setCloseLockApp } = useBoundStore()
  const router = useRouter()
  const { t } = useTranslation()
  const [queryKey, setQueryKey] = useState<{
    page: number
    limit: number
    key: number
  }>({
    page: 1,
    limit: 9,
    key: 1
  })

  const {
    trigger: triggerFetchProfile,
    data: dataProfile,
    isLoading: isLoadingProfile,
    isFetching,
    fetchNextPage,
    hasNextPage,
    totalRowCount,
    updateLocalRecord
  } = useQueryProfileList({
    variables: queryKey
  })

  useEffect(() => {
    if (!(isFetching && isLoadingProfile === false)) {
      setCloseLockApp()
    }
  }, [isFetching, isLoadingProfile])

  const onSubmitDuplicateProfile = (profileId: string) => {
    return (
      onDuplicateProfile &&
      onDuplicateProfile(profileId).then((rs) =>
        setQueryKey({
          page: 1,
          limit: 9,
          key: (queryKey?.key || 1) + 1
        })
      )
    )
  }

  const onSubmitCreateProfile = () => {
    return (
      onCreateProfile &&
      onCreateProfile().then(
        (rs: { profileCreate: { profile: { id: string } } }) => {
          setCloseLockApp()
          router.push(
            configuration.path.resumes.detail(rs.profileCreate?.profile?.id)
          )
        }
      )
    )
  }

  const onSubmitDeleteProfile = (profileId: string) => {
    onDeleteProfile &&
      onDeleteProfile(profileId).then(() =>
        setQueryKey({
          page: 1,
          limit: 9,
          key: (queryKey?.key || 1) + 1
        })
      )
  }

  const onSubmitUpdateProfile = (profileId: string, title: string | number) => {
    return (
      onUpdateProfile && onUpdateProfile(profileId, updateLocalRecord, title)
    )
  }

  useEffect(() => {
    triggerFetchProfile()
  }, [])

  return (
    <div className="flex justify-center pb-6">
      <div
        className={cn(
          'w-full max-w-[1920px] px-8',
          isLoadingProfile ? 'pt-6' : 'pt-5'
        )}>
        {isLoadingProfile ? (
          <div className="flex flex-col items-center">
            <div className="grid w-full max-w-[1217px] mobile:grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3">
              <div>
                <Skeleton className="mb-3 h-[19px] w-2/3 rounded-sm bg-gray-100" />
                <Skeleton className="mb-6 h-[15px] w-1/2 rounded-sm bg-gray-100" />
              </div>
            </div>
            <div className="grid w-full max-w-[1217px] gap-10 mobile:grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3">
              <Skeleton className="h-full w-full rounded-lg bg-gray-100 desktop:h-[550px]" />
              <Skeleton className="h-full w-full rounded-lg bg-gray-100 desktop:h-[550px]" />
              <Skeleton className="h-full w-full rounded-lg bg-gray-100 desktop:h-[550px]" />
            </div>
          </div>
        ) : (
          <>
            <div className="sticky top-0 z-10 flex justify-between bg-white py-5">
              <div className="bg-white">
                <TypographyText className="text-xl font-medium text-gray-900">
                  {t('dashboard:title')}
                </TypographyText>
                <TypographyText className="mt-0.5 text-base text-gray-900">
                  {t('dashboard:description')}
                </TypographyText>
              </div>
              <Button
                iconMenus="Plus"
                label="New resume"
                size="sm"
                onClick={onSubmitCreateProfile}
              />
            </div>
            {totalRowCount === 0 ? (
              <EmptyView onSubmitCreateProfile={onSubmitCreateProfile} />
            ) : (
              <>
                <div className="flex justify-center">
                  <div className="grid w-full max-w-[1217px] gap-x-10 gap-y-6 mobile:grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3">
                    {dataProfile?.map((item) => (
                      <div key={item.id}>
                        <ResumeCard
                          data={item}
                          onSubmitDuplicateProfile={onSubmitDuplicateProfile}
                          onSubmitDeleteProfile={onSubmitDeleteProfile}
                          onSubmitUpdateProfile={onSubmitUpdateProfile}
                        />
                      </div>
                    ))}

                    {totalRowCount === 1 && (
                      <SuggestCreateCVCard
                        data={dataProfile}
                        onSubmitDuplicateProfile={onSubmitDuplicateProfile}
                        onSubmitCreateProfile={onSubmitCreateProfile}
                      />
                    )}
                  </div>
                </div>

                {hasNextPage &&
                  dataProfile?.length &&
                  dataProfile?.length > 0 &&
                  dataProfile?.length < (totalRowCount || 9) && (
                    <div className="mt-6 flex justify-center">
                      <TextButton
                        size="md"
                        underline={false}
                        type="tertiary"
                        onClick={() => fetchNextPage()}
                        label={t('button:view_more')}
                      />
                    </div>
                  )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DashboardView
